import 'react-dropzone-uploader/dist/styles.css'
import React from 'react'
 
const Test = props => {
       

      return (
        <div style={{ height: '100vh', width: '100%' }}>
       
                   </div>

      )
   
}

export default Test;

/*<GoogleMapReact
yesIWantToUseGoogleMapApiInternals
onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
</GoogleMapReact>
*/